import * as Sentry from "@sentry/nextjs";
import { Button } from "design-system/ui/atoms/button";
import { Text } from "design-system/ui/atoms/texts/text";
import { MoveLeftIcon } from "lucide-react";
import Error from "next/error";
import { NonAuthLayout } from "~/ui/screens/NonAuthLayout/layout";
export const CustomErrorComponent = ({ description = "We've logged the error and will be working on it.", ...props }) => {
    return (
        <NonAuthLayout heading={`Error: ${props.statusCode} 🛠️👾`}>
            <Text color="black-1000" size="15" lineHeight={20} className="mt-8 flex flex-col items-center text-center">
                {description}
            </Text>
            <div className="flex justify-center ds-mt-32">
                <Button
                    variant="secondary_outline"
                    className="mt-8 px-3 mx-auto"
                    onClick={() => {
                        window.location.href = "/apps?category=all";
                    }}
                    size={"36"}
                >
                    <MoveLeftIcon height={14} width={14} className="mr-2" />
                    Go back to Home
                </Button>
            </div>
        </NonAuthLayout>
    );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
CustomErrorComponent.getInitialProps = async (contextData: any) => {
    // In case this is running in a serverless function, await this in order to give Sentry
    // time to send the error before the lambda exits
    await Sentry.captureUnderscoreErrorException(contextData);
    // This will contain the status code of the response
    return Error.getInitialProps(contextData);
};

export default CustomErrorComponent;
